import React from "react"
import { graphql, Link } from "gatsby"
import { Box, Flex, Text } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsPage = ({ data }) => { 
  const { edges: posts } = data.allMdx
  
  return (
    <Layout>
      <SEO title="Home" />
      
      <Text as="h2" variant="h2">News</Text>

      {posts.map(({ node: post }) => (
        <Box key={post.id} sx={{ mb: 5 }}>
          <Text as="p" variant="body">{post.frontmatter.date}</Text>
          {post.frontmatter.external_url ?
            <a href={post.frontmatter.external_url} target="_blank" rel="noreferrer">
              <Text as="h3" variant="link" sx={{ fontSize: 3 }}>{post.frontmatter.title} ↬</Text>
            </a> :
            <Link to={post.fields.slug}>
              <Text as="h3" variant="link" sx={{ fontSize: 3 }}>{post.frontmatter.title}</Text>
            </Link>
          }
        </Box>
      ))}
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { category: { eq: "news" } } }) {
      edges {
        node {
          frontmatter {
            title
            category
            date(formatString: "MMMM DD, YYYY")
            external_url
          }
          fields {
            slug
          }
        }
      }
    }
  }
`